$(document).ready(function () {
	//Hide element when smaller than 1025

	if ($(window).width() < 1025) {
		$(".navbar-primary-menu").fadeIn(function () {
			$(".navbar-primary-menu").css({
				display: "block",
			});
		});
		$(".header-btn-wrap").fadeIn(function () {
			$(".header-btn-wrap").css({
				display: "flex",
			});
		});
	}

	swiperInit();
	toggleFunc();
	filterDisplay();
	mappingFunc();
	tabFunc();
});
window.addEventListener("resize", mappingFunc);
Fancybox.bind("[data-fancybox]", {
	parentEl: document.forms[0], // Element containing main structure
});

function tabFunc() {
	$(".tab-nav li a").on("click", function () {
		$(this).parents(".tab-nav").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents("section").find(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
		$("#" + display)
			.hasClass("active")
			.find(".tabs-option")
			.removeClass("active")
			.find(".tabs-option:first-child")
			.addClass("active");
	});
	// Attach event handler to a common ancestor element using event delegation

	// Attach event handler to .filter-tab-select elements
	$(".filter-tab-select").on("change", "select", function () {
		var option = $(this).find("option:selected").attr("data-tab");
		console.log(option);
		var tabItem = $(this).closest(".tab-item");
		tabItem.find(".tabs-option").removeClass("active");
		if (tabItem.length > 0) {
			tabItem.find($("#" + option)).addClass("active");
		}
	});
}

function filterDisplay() {
	$(".filter-table-wrap").each(function () {
		let x = 8;
		$(this).find("tbody tr").slice(0, 8).show();
		let size = $(this).find("tbody tr").length;
		if (size < x || size == x) {
			$(this).find(".filter-btn-wrap").addClass("disble-btn");
		}
		$(".filter-table-wrap")
			.find(".btn-view-more")
			.on("click", function (e) {
				e.preventDefault();
				x = x + 4;
				$(this)
					.parents(".filter-table-wrap")
					.find("tbody tr")
					.slice(0, x)
					.slideDown();
				if (x >= size || size < x) {
					$(this).parent().addClass("disble-btn");
				}
			});
	});
}
function toggleFunc() {
	document
		.querySelector(".menu-toggle")
		.addEventListener("click", function (event) {
			document.querySelector(".hamburger").classList.toggle("is-active");
			document
				.querySelector(".mobile-menu-wrap")
				.classList.toggle("is-open");
			document.querySelector("body").classList.toggle("overlay-bg");
		});
	document
		.querySelector(".search-toggle")
		.addEventListener("click", function (event) {
			document
				.querySelector(".search-overlay")
				.classList.toggle("active");
			$(".search-toggle").toggleClass("icon-change");
		});
	document.addEventListener("click", function (event) {
		if (
			!event.target.closest(".search-toggle") &&
			!event.target.closest(".search-overlay")
		) {
			document
				.querySelector(".search-overlay")
				.classList.remove("active");
			document
				.querySelector(".search-toggle")
				.classList.remove("icon-change");
		}
	});
	if ($(window).width() < 1025) {
		$(".navbar-primary-menu .title em").on("click", function () {
			$(this).parent().next().slideToggle();
		});
	}
}

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".primary-banner .swiper-pagination",
			clickable: true,
		},
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
	});
	var topBanner = new Swiper(".top-banner .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
	});

	$(".trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});
	$(".four-swiper .swiper").each(function (index, element) {
		const $element = $(element);
		const parent = $element.parent();
		const prev = parent.find(".nav-prev");
		const next = parent.find(".nav-next");
		const options = {
			speed: 750,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: next,
				prevEl: prev,
			},
		};
		$element.addClass(`t-${index}`);
		prev.addClass(`bp-${index}`);
		next.addClass(`bn-${index}`);
		new Swiper(`.t-${index}`, {
			...options,
			navigation: {
				...options.navigation,
				nextEl: `.bn-${index}`,
				prevEl: `.bp-${index}`,
			},
		});
	});
}

function mappingFunc() {
	new MappingListener({
		selector: ".navbar-primary-menu",
		mobileWrapper: ".mobile-menu-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-wrap",
		desktopMethod: "insertBefore",
		breakpoint: 1024.1,
	}).watch();
	new MappingListener({
		selector: ".header-btn-wrap",
		mobileWrapper: ".mobile-menu-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".language-wrap",
		desktopMethod: "insertBefore",
		breakpoint: 1024.1,
	}).watch();
}
